import React from "react";

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header navbar-right">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">החלף ניווט</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
            <span
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "-25px", // Adjust the value to move the logo and text up
              }}
            >
              <img
                src="img/logos/android-chrome-192x192.png"
                alt="R-Net Logo"
                style={{ height: "70px", marginLeft: "10px", borderRadius: "10px" }}
              />
            </span>
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse navbar-left"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav">
            <li>
              <a href="#contact" className="page-scroll">
                צור קשר
              </a>
            </li>
            <li>
              <a href="#team" className="page-scroll">
                צוות
              </a>
            </li>
            <li>
              <a href="#testimonials" className="page-scroll">
                המלצות
              </a>
            </li>
            <li>
              <a href="#portfolio" className="page-scroll">
                גלריה
              </a>
            </li>
            <li>
              <a href="#services" className="page-scroll">
                שירותים
              </a>
            </li>
            <li>
              <a href="#about" className="page-scroll">
                אודות
              </a>
            </li>
            <li>
              <a href="#features" className="page-scroll">
                תכונות
              </a>
            </li>
            <li>
              <a href="#video" className="page-scroll">
                פרסום
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
