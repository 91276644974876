import React from "react";

export const AboutUs = (props) => { 
  return (
    <div id="video">
      <div className="container">
      <div className="about__videoCard grid">
        <div className="about__cardText">
          <h2>התקנת דלתות פנים וחוץ ברמה הגבוה ביותר בארץ</h2>
          <p>
          א.ד. דלתות 
          </p>
        </div>
        <div className="about__cardVideo">
        <video 
    id="myVideo" 
    src="/videos/01.mp4" 
    autoPlay 
    muted 
    loop 
    playsInline 
    type="video/mp4" 
    onClick={() => {
        const video = document.getElementById('myVideo');
        video.muted = !video.muted; // Toggle mute
    }}
></video>
        </div>
      </div>
      </div>
      </div>
  );
}
