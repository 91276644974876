import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";

const initialState = {
  name: "",
  email: "",
  message: "",
};

export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_u1z35xf", "template_jxgaefu", e.target, "WUfIrZrSQh-kQI2e0")
      .then(
        (result) => {
          console.log(result.text);
          setSuccessMessage("ההודעה שלך נשלחה בהצלחה!");
          clearState();
        },
        (error) => {
          console.log(error.text);
          setSuccessMessage("הייתה בעיה בשליחת ההודעה שלך.");
        }
      );
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>צור קשר</h2>
                <p>
                  אנא מלא את הטופס למטה כדי לשלוח לנו הודעה ואנו נחזור אליך בהקדם האפשרי.
                </p>
              </div>
              <form name="sentMessage" validate="true" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="שם"
                        required
                        value={name}
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="כתובת מגורים"
                        required
                        value={email}
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="הודעה ופרטים לחזרה, נחזור אלייך בהקדם האפשרי."
                    required
                    value={message}
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  שלח הודעה
                </button>
              </form>
              {successMessage && (
                <div className="alert alert-success" style={{ marginTop: "20px" }}>
                  {successMessage}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            {/* Contact Info */}
            <div className="contact-item">
              <h3>פרטי התקשרות</h3>
            </div>
            <div className="contact-item">
              <p>
                <span>
                <i className="fab fa-whatsapp"></i>  וואטסאפ
                </span>{" "}
                <a href={`https://wa.me/972522617505`} style={{ color: "white" }} target="_blank" rel="noopener noreferrer">
                  {props.data ? props.data.phone : "טוען..."}
                </a>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> טלפון
                </span>{" "}
                <a href={`tel:${props.data ? props.data.phone : ""}`} style={{ color: "white" }}>
                  {props.data ? props.data.phone : "טוען..."}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p style={{ margin: 0, paddingBottom: "10px" }}>&copy; 2024 R & R-Net. All rights reserved.</p>
          <div style={{ display: "inline-block" }}>
            <a href="/img/popup.png" target="_blank" rel="noopener noreferrer" style={{ margin: "0 10px" }}>QR</a>  |
            <a href="https://roeinizri.com/terms-of-use.html" target="_blank" rel="noopener noreferrer" style={{ margin: "0 10px" }}>Terms of Use</a>  |
            <a href="https://roeinizri.tawk.help" target="_blank" rel="noopener noreferrer" style={{ margin: "0 10px" }}>Help</a>
          </div>
        </div>
      </div>
    </div> 
    
  );
};
